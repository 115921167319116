import { useState, useContext, useEffect } from "react";
import { isMobile } from "../utils/settings";
import { FaAnglesRight } from "react-icons/fa6";
import { AppContext } from "../State/Context";
import { ActionType, hexHeight, hexWidth } from "../State/State";
import { Hexagen } from "../types";
import { askAPI } from "../utils/api";
import { RequestMethod } from "../utils/api";
import useAsyncEffect from "use-async-effect";

enum MenuLeftType {
  ALL_TIME_TOP = "all_time_top",
  MONTH_TOP = "month_top",
  WEEK_TOP = "week_top",
  RECENTLY_CREATED = "recently_created",
  MOST_RARE_ALL_TIME = "most_rare_all_time",
}

export const MenuLeft = () => {
  const { state, dispatch } = useContext(AppContext);
  const { isMenuLeftOpen, statistic } = state;
  const [selectedType, setSelectedType] = useState<MenuLeftType>(
    MenuLeftType.RECENTLY_CREATED
  );

  const [hexagensList, setHexagensList] = useState<Hexagen[]>([]);

  const topPlayers = statistic?.richestPlayers || [];

  const handlePlayerClick = (uid: string) => {
    dispatch({ type: ActionType.SELECT_PLAYER, uid });
    dispatch({ type: ActionType.TOGGLE_PLAYER_INFO_POPUP });
  };

  useAsyncEffect(async () => {
    if (isMenuLeftOpen) {
      const urlList = {
        [MenuLeftType.RECENTLY_CREATED]: "/api/hexagen/recent",
        [MenuLeftType.ALL_TIME_TOP]: "/api/hexagen/most-upvoted-all-time",
        [MenuLeftType.MONTH_TOP]: "/api/hexagen/most-upvoted-last-month",
        [MenuLeftType.WEEK_TOP]: "/api/hexagen/most-upvoted-last-week",
        [MenuLeftType.MOST_RARE_ALL_TIME]: "/api/hexagen/most-rare-all-time",
      };

      const url = urlList[selectedType];
      const hexagens = await askAPI<Hexagen[]>(url, RequestMethod.GET);
      setHexagensList(hexagens || []);
    }
  }, [selectedType, isMenuLeftOpen]);

  return (
    <>
      {/* Top players ranking */}
      {topPlayers.length > 0 && (
        <div
          className="fixed user-select-none text-xs"
          style={{
            top: "50px",
            left: "34px",
          }}
        >
          <ul className="flex flex-col">
            {topPlayers.map((player, i) => (
              <li
                key={player.uid || i}
                className="bg-black text-amber-50 opacity-90 bg-opacity-70 px-2 pr-4 py-1 w-fit flex items-center"
              >
                {i === 0 && <span className="text-[16px] pr-2">👑 </span>}
                {i === 1 && <span className="text-[16px] pr-2">✨ </span>}
                {i === 2 && <span className="text-[16px] pr-2">💛 </span>}

                <span
                  className="cursor-pointer hover:underline"
                  onClick={() => player.uid && handlePlayerClick(player.uid)}
                >
                  {player.name}
                </span>
                <span className="text-[10px] pl-1">/{player.points}</span>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div
        className={`antique absolute left-0 top-[45px] bottom-[37px] transition-all duration-300 z-100 ${
          isMenuLeftOpen ? (isMobile() ? "w-full" : "w-[450px]") : "w-0"
        }`}
      >
        {!isMenuLeftOpen ? (
          <div
            className="absolute top-5 pt-[2px] pb-[1px] px-[3px] right-[-29px] antique cursor-pointer text-2xl hover:text-yellow-100 hover:bg-gray-800"
            onClick={() => dispatch({ type: ActionType.TOGGLE_MENU_LEFT })}
          >
            <FaAnglesRight />
          </div>
        ) : (
          <div
            className="absolute right-[17px] top-[6px] select-none cursor-pointer text-[25px]"
            onClick={() => dispatch({ type: ActionType.TOGGLE_MENU_LEFT })}
          >
            ✕
          </div>
        )}

        <div className={`${isMenuLeftOpen ? "block" : "hidden"}`}>
          <div className="flex justify-between pt-8 pb-4 pr-2 overflow-hidden">
            <div className="flex justify-center max-w-[350px] mx-auto text-xs">
              <div
                className="px-2 text-center cursor-pointer select-none underline hover:no-underline"
                onClick={() => setSelectedType(MenuLeftType.RECENTLY_CREATED)}
              >
                Recently Created
              </div>
              <div
                className="px-2 text-center cursor-pointer select-none underline hover:no-underline"
                onClick={() => setSelectedType(MenuLeftType.WEEK_TOP)}
              >
                Week Top
              </div>
              <div
                className="px-2 text-center cursor-pointer select-none underline hover:no-underline"
                onClick={() => setSelectedType(MenuLeftType.MONTH_TOP)}
              >
                Month Top
              </div>
              <div
                className="pr-2 text-center cursor-pointer select-none underline hover:no-underline"
                onClick={() => setSelectedType(MenuLeftType.ALL_TIME_TOP)}
              >
                All Time Top
              </div>
            </div>
          </div>
          <div className="px-4 h-[calc(100vh-165px)] overflow-y-auto">
            <div className="grid grid-cols-1 gap-4">
              {hexagensList.map((hexagen: Hexagen, i: number) => (
                <HexagenListItem
                  key={hexagen.uid || i}
                  hexagen={hexagen}
                  closeMenu={() =>
                    dispatch({ type: ActionType.TOGGLE_MENU_LEFT })
                  }
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

interface HexagenListItemProps {
  closeMenu: () => void;
  hexagen: Hexagen;
}

const HexagenListItem = ({ hexagen, closeMenu }: HexagenListItemProps) => {
  const { dispatch, state } = useContext(AppContext);

  const handleClick = () => {
    dispatch({ type: ActionType.SELECT_HEX, x: hexagen.x, y: hexagen.y });
    dispatch({ type: ActionType.TOGGLE_HEX_INFO_POPUP });
    dispatch({
      type: ActionType.CHANGE_WORLD_COORDINATES,
      x: -hexagen.x * hexWidth * state.worldScale,
      y: -(hexagen.y - 2) * hexHeight * state.worldScale,
      logggable: true,
    });
    closeMenu();
  };

  return (
    <div className="w-full h-auto cursor-pointer flex" onClick={handleClick}>
      <div className="w-[60%] h-auto flex items-center justify-center pl-4">
        <img
          src={`/api/slow-images/x${hexagen.x}y${hexagen.y}.png`}
          alt={`top-hex-${hexagen.uid}`}
          className="max-w-full"
        />
      </div>
      <div className="w-[40%] text-xs p-2 pl-4 flex flex-col justify-center">
        <div className="mb-1">x: {hexagen.x}</div>
        <div className="mb-1">y: {hexagen.y}</div>
        <div className="break-words mb-1">prompt: {hexagen.prompt}</div>
        <div className="mb-1">upvotes: {hexagen.upvotes}</div>
        <div>points: {hexagen.points}</div>
      </div>
    </div>
  );
};
