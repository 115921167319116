import { RarityLevel } from "./utils/rarity";

export interface Player {
  uid: string;
  name: string;
  points: number;
  hexagens: Hexagen[];
  role: "admin" | "player";
  hexagensNumber: number;
  top3Rarities: RarityLevel[];
  nftsNumber: number;
  isTester: boolean;
  crypto: {
    walletAddress: string;
    updateDate: Date;
  };
}

export interface PlayerProfileProps {
  player: Player;
}

export interface SystemMessage {
  systemMessage: "error" | "success";
  text: string;
}

export interface Hexagen {
  status: HexagenStatus;
  uid: string;
  x: number;
  y: number;
  creator: string;
  owner: string;
  description: string;
  regeneratedAt: number;
  points: number;
  createdAt: number;
  prompt: string;
  views: number;
  upvotes: number;
  commentsNum: number;
  nft?: {
    tokenId: string;
    openseaUrl: string;
  };
}

export enum HexagenStatus {
  GENERATING = "generating",
  REGENERATING = "regenerating",
  READY = "ready",
}

// Interface representing notification coordinates
interface NotificationCoordinates {
  x: number;
  y: number;
}

// Main notification interface
export interface INotification {
  from: string;
  to: string;
  coordinates?: NotificationCoordinates;
  type: NotificationType;
  message: string;
  isRead: boolean;
  createdAt: string;
}

export enum NotificationType {
  UPVOTE = "upvote",
  COMMENT = "comment",
}

export interface Statistic {
  totalPlayers?: number;
  totalHexagons?: number;
  richestPlayers?: Player[];
  notifications?: INotification[];
  player?: Player;
}
