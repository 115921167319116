import { useContext } from "react";
import { AppContext } from "../State/Context";
import { BasicPopup } from "./BasicPopup";
import { ActionType } from "../State/State";

export const AboutGamePopup: React.FC = () => {
  const { dispatch } = useContext(AppContext);
  const closePopup = () => {
    dispatch({ type: ActionType.TOGGLE_ABOUT_GAME_POPUP });
  };
  return (
    <BasicPopup onClose={closePopup}>
      <div className="">
        <h1 className="text-2xl font-bold pb-4">About Game and Rules</h1>
        <p>
          <b>Hexagen.World</b> is a collectively generated game world created by
          all players.
        </p>
        <p className="py-6 font-bold text-lg">The rules are simple: </p>
        <ul className="list-disc pl-4 [&>li]:mb-3">
          <li>
            1️⃣ Explore the edge of the world using the map or simply drag the
            canvas to navigate.
          </li>
          <li>2️⃣ Click on an empty hexagon at the edge to begin.</li>
          <li>
            3️⃣ Come up with a prompt and generate a piece of the world in the
            selected hexagon.
          </li>
          <li>
            4️⃣ Your hexagon will be evaluated by AI in creativity points for the
            creativity you've shown.
          </li>
        </ul>
        <div className="font-bold py-6 text-lg">
          How to Earn Points for Generation
        </div>
        <div className="mb-4 [&>div]:mb-3">
          <div className="mb-2">
            <div className="font-bold">1. Starting Balance:</div>
            <div className="ml-4">
              Every player starts with 100 points after registering.
            </div>
          </div>

          <div className="mb-2">
            <div className="font-bold">2. Passive Earning:</div>
            <ul className="ml-4 list-disc pl-4">
              <li>
                Players earn 1 point per hour if their balance is less than 1000
                points.
              </li>
              <li>You can earn up to 60 points at a time.</li>
            </ul>
          </div>

          <div className="mb-2">
            <div className="font-bold">3. Upvote Bonus:</div>
            <ul className="ml-4 list-disc pl-4">
              <li>
                You get up to 40 points for upvoting someone else's hexagon.
              </li>
              <li>Recently created hexagons give higher bonus.</li>
              <li>
                The bonus applies if you've given fewer than 6 upvotes in the
                past 24 hours.
              </li>
            </ul>
          </div>

          <div className="mb-2">
            <div className="font-bold">
              4. Rewards for Your Hexagon Upvotes:
            </div>
            <ul className="ml-4 list-disc pl-4">
              <li>
                Bonus starts at 10% of hexagon value for Common rarity and
                increases by 10% for each rarity level (Uncommon: 20%, Rare:
                30%, Epic: 40%, Legendary: 50% and higher)
              </li>
              <li>
                You can only receive the bonus for up to 3 upvotes per player in
                a 30-day period, but players can upvote your hexagon as many
                times as they want.
              </li>
            </ul>
          </div>

          <div className="mb-2">
            <div className="font-bold">5. Promo Codes:</div>
            <ul className="ml-4 list-disc pl-4">
              <li>You can earn extra points by redeeming promo codes.</li>
              <li>Promo codes are shared in places like our Discord.</li>
            </ul>
          </div>
        </div>
        <p>
          This is an <b>early preview version</b>, so generations take up to 30
          seconds, some things may not work, and the functionality is not
          complete.
        </p>

        {/* <div>
          <h1 className="text-2xl font-bold pb-4 pt-6">
            How to Mint an NFT in Hexagen.World?
          </h1>
          <p className="py-4">
            Minting your very own NFT in Hexagen.World is easy and exciting!
            Just follow these simple steps.
          </p>

          <h2 className="text-lg font-bold py-3">
            1. Set Up and Connect Your Crypto Wallet
          </h2>
          <ul className="list-disc pl-4 [&>li]:mb-3">
            <li>
              <strong>Install MetaMask:</strong> MetaMask is a browser-based
              crypto wallet. You can add it as an extension to Google Chrome{" "}
              <a
                href="https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
                target="_blank"
                rel="noreferrer"
                className="text-blue-500 hover:text-blue-700 underline"
              >
                here
              </a>
              .
            </li>
            <li>
              <strong>Create a Wallet:</strong> Follow the instructions in
              MetaMask to create your wallet and get your wallet address.
            </li>
            <li>
              <strong>Connect Your Wallet in the Game:</strong>
              <ol className="list-decimal pl-4 mt-2 [&>li]:mb-2">
                <li>
                  Open the <strong>NFT Menu</strong> in the game (look for the
                  button on the top bar).
                </li>
                <li>
                  Click <strong>"Connect 🦊 MetaMask Wallet"</strong>.
                </li>
                <li>
                  <strong>Sign the Request in MetaMask:</strong> This step
                  confirms that you own the wallet, ensuring that only you can
                  access your NFTs.
                </li>
              </ol>
            </li>
          </ul>

          <h2 className="text-lg font-bold py-3">2. Mint Your NFT</h2>
          <ul className="list-disc pl-4 [&>li]:mb-3">
            <li>
              <strong>Find an Eligible Hexagon:</strong> Look for a hexagon that
              meets the minting rules. It must have a{" "}
              <strong>Epic or higher rarity level</strong>.
            </li>
            <li>
              <strong>Start the Minting Process:</strong>
              <ol className="list-decimal pl-4 mt-2 [&>li]:mb-2">
                <li>
                  Open the <strong>hexagon's information panel</strong>.
                </li>
                <li>
                  Click the <strong>"Mint NFT"</strong> button.
                </li>
                <li>Confirm your intention in the warning popup.</li>
              </ol>
            </li>
            <li>
              <strong>Wait for the Minting to Complete:</strong> Minting
              typically takes less than a minute. Please{" "}
              <strong>don't close the minting window</strong> until it's
              finished.
            </li>
            <li>
              <strong>Confirmation and OpenSea Link:</strong> After minting is
              complete, you'll see a confirmation message along with a link to
              view your NFT on <strong>OpenSea</strong>.
            </li>
            <li>
              <strong>Verify Ownership:</strong> Click the OpenSea link and
              ensure that your wallet address is listed as the{" "}
              <strong>Owner</strong> of the NFT.
            </li>
          </ul>

          <h2 className="text-lg font-bold py-3">Additional Notes</h2>
          <ul className="list-disc pl-4 [&>li]:mb-3">
            <li>
              <strong>Trading Your NFT:</strong> You can transfer your NFT to
              others or list it for sale on OpenSea.
            </li>
            <li>
              <strong>Claiming a Hexagon:</strong> If someone transfers an NFT
              to you, you can claim the corresponding hexagon in the game:
              <ol className="list-decimal pl-4 mt-2 [&>li]:mb-2">
                <li>
                  Go to the <strong>NFT Menu</strong> in the game.
                </li>
                <li>
                  Use the <strong>"Claim your Hexagen from NFTs"</strong>{" "}
                  feature and enter the hexagon's coordinates.
                </li>
                <li>
                  The game will verify ownership via blockchain and assign the
                  hexagon to your account.
                </li>
              </ol>
            </li>
          </ul>

          <h2 className="text-lg font-bold py-3">
            Special Features of Minted Hexagons
          </h2>
          <ul className="list-disc pl-4 [&>li]:mb-3">
            <li>
              <strong>Immutable:</strong> Once minted, hexagons cannot be
              regenerated or captured by others.
            </li>
            <li>
              <strong>Full Bonus:</strong> Hexagons minted as NFTs provide their{" "}
              <strong>full bonus value</strong> when upvoted by other players.
            </li>
            <li>
              Bonus starts at 10% of hexagon value for Common rarity and
              increases by 10% for each rarity level (Uncommon: 20%, Rare: 30%,
              Epic: 40%, Legendary: 50% and higher)
            </li>
          </ul>

          <p className="py-4">
            Enjoy creating and owning unique NFTs in Hexagen.World! 🚀
          </p>
        </div> */}

        <p className="mt-4">
          ©️ This project was created by <b>Mikhail Dunaev</b>, and all
          intellectual property rights are owned by <b>Taevavõrk OÜ.</b>
        </p>
        <p className="mt-4">
          Follow me on{" "}
          <a
            href="https://www.linkedin.com/in/mikhail-dunaev/"
            rel="noreferrer"
            target="_blank"
          >
            LinkedIn
          </a>{" "}
          to learn about updates and other projects!
        </p>
        <p className="mt-4">Made in Estonia 🇪🇪 with 💙</p>
      </div>
    </BasicPopup>
  );
};
