import hash from "object-hash";
import { Hexagen, Player, Statistic } from "../types";
import {
  calculateNewWorldCoordinates,
  getInitialCoordinates,
} from "./stateUtils";
import { INotification } from "../types";

export const hexWidth = 213;
export const hexHeight = 146;

// 1 - ADD PROPERTY TO STATE INTERFACE
export interface AppState {
  hash: string;
  player: Player | null;
  isPlayerProfilePopupOpen: boolean;
  isConfirmDeletePopupOpen: boolean;
  isLoginPopupOpen: boolean;
  isHexInfoPopupOpen: boolean;
  isHexGeneratePopupOpen: boolean;
  isPlayerInfoPopupOpen: boolean;
  isAboutGamePopupOpen: boolean;
  isMenuPopupOpen: boolean;
  isGeneratedAwardPopupOpen: boolean;
  isMostViewedPopupOpen: boolean;
  isGlobalChatPopupOpen: boolean;
  isRedeemPopupOpen: boolean;
  isNFTAlertPopupOpen: boolean;
  isNotificationsPopupOpen: boolean;
  isNftPopupOpen: boolean;
  isNFTConfirmMintingPopupOpen: boolean;
  statistic: Statistic;
  worldX: number;
  worldY: number;
  worldScale: number;
  selectedHexCoord: { x: number; y: number } | null;
  selectedHexInfo: Hexagen | null;
  generatingNowHexs: { x: number; y: number }[];
  regeneratingNowHexs: { x: number; y: number }[];
  isWorldDragging: boolean;
  selectedPlayerUID: string | null;
  booleanMap: { [key: string]: boolean };
  notifications: INotification[];
  ratings: {
    mostViewed: Hexagen[];
    mostViewedLastWeek: Hexagen[];
    lastCreated: Hexagen[];
    topPlayers: Player[];
    mostUpvotedLastWeek: Hexagen[];
    highestPointsLastWeek: Hexagen[];
  };
  isMenuLeftOpen: boolean;
}

// 2 - ADD INITIAL STATE VALUE
export const blankState: AppState = {
  hash: "",
  player: null,
  booleanMap: {},
  statistic: {},
  isPlayerProfilePopupOpen: false,
  isConfirmDeletePopupOpen: false,
  isLoginPopupOpen: false,
  isHexInfoPopupOpen: false,
  isHexGeneratePopupOpen: false,
  isPlayerInfoPopupOpen: false,
  isAboutGamePopupOpen: false,
  isMenuPopupOpen: false,
  isGeneratedAwardPopupOpen: false,
  isMostViewedPopupOpen: false,
  isGlobalChatPopupOpen: false,
  isRedeemPopupOpen: false,
  isNFTAlertPopupOpen: true,
  isNotificationsPopupOpen: false,
  isNftPopupOpen: false,
  isNFTConfirmMintingPopupOpen: false,
  selectedHexCoord: null,
  selectedHexInfo: null,
  generatingNowHexs: [],
  regeneratingNowHexs: [],
  isWorldDragging: false,
  selectedPlayerUID: null,
  worldScale: 1,
  notifications: [],
  ratings: {
    mostViewed: [],
    mostViewedLastWeek: [],
    lastCreated: [],
    topPlayers: [],
    mostUpvotedLastWeek: [],
    highestPointsLastWeek: [],
  },
  worldX: 0,
  worldY: 0,
  isMenuLeftOpen: false,
};
blankState.hash = hash(blankState);
const coordinates = getInitialCoordinates();
blankState.worldX = coordinates.worldX;
blankState.worldY = coordinates.worldY;

// 3 - DEFINE ACTION TYPES
export enum ActionType {
  UPDATE_PLAYER = "UPDATE_PLAYER",
  DELETE_PLAYER = "DELETE_PLAYER",
  CHANGE_WORLD_COORDINATES = "CHANGE_WORLD_COORDINATES",
  SELECT_HEX = "SELECT_HEX",
  DESELECT_HEX = "DESELECT_HEX",
  ADD_SELECTED_HEX_INFO = "ADD_SELECTED_HEX_INFO",
  TOGGLE_PLAYER_PROFILE_POPUP = "TOGGLE_PLAYER_PROFILE_POPUP",
  TOGGLE_DELETE_POPUP = "TOGGLE_DELETE_POPUP",
  REMOVE_SELECTED_HEX_INFO = "REMOVE_SELECTED_HEX_INFO",
  TOGGLE_LOGIN_POPUP = "TOGGLE_LOGIN_POPUP",
  TOGGLE_HEX_INFO_POPUP = "TOGGLE_HEX_INFO_POPUP",
  TOGGLE_HEX_GENERATE_POPUP = "TOGGLE_HEX_GENERATE_POPUP",
  TOGGLE_NFT_POPUP = "TOGGLE_NFT_POPUP",
  TOGGLE_NFT_CONFIRM_MINTING_POPUP = "TOGGLE_NFT_CONFIRM_MINTING_POPUP",
  TOGGLE_GENERATED_AWARD_POPUP = "TOGGLE_GENERATED_AWARD_POPUP",
  TOGGLE_PLAYER_INFO_POPUP = "TOGGLE_PLAYER_INFO_POPUP",
  TOGGLE_ABOUT_GAME_POPUP = "TOGGLE_ABOUT_GAME_POPUP",
  ADD_GENERATING_NOW_HEX = "ADD_GENERATING_NOW_HEX",
  REMOVE_GENERATING_NOW_HEX = "REMOVE_GENERATING_NOW_HEX",
  ADD_REGENERATING_NOW_HEX = "ADD_REGENERATING_NOW_HEX",
  REMOVE_REGENERATING_NOW_HEX = "REMOVE_REGENERATING_NOW_HEX",
  START_WORLD_DRAGGING = "START_WORLD_DRAGGING",
  STOP_WORLD_DRAGGING = "STOP_WORLD_DRAGGING",
  SELECT_PLAYER = "SELECT_PLAYER",
  DESELECT_PLAYER = "DESELECT_PLAYER",
  TOGGLE_MENU_POPUP = "TOGGLE_MENU_POPUP",
  UPDATE_STATISTIC = "UPDATE_STATISTIC",
  ZOOM_IN = "ZOOM_IN",
  ZOOM_OUT = "ZOOM_OUT",
  TOGGLE_MOST_VIEWED_POPUP = "TOGGLE_MOST_VIEWED_POPUP",
  SET_MOST_VIEWED_RATING = "SET_MOST_VIEWED_RATING",
  SET_MOST_VIEWED_LAST_WEEK_RATING = "SET_MOST_VIEWED_LAST_WEEK_RATING",
  SET_LAST_CREATED_RATING = "SET_LAST_CREATED_RATING",
  SET_TOP_PLAYERS_RATING = "SET_TOP_PLAYERS_RATING",
  SET_MOST_UPVOTED_LAST_WEEK_RATING = "SET_MOST_UPVOTED_LAST_WEEK_RATING",
  SET_HIGHEST_POINTS_LAST_WEEK_RATING = "SET_HIGHEST_POINTS_LAST_WEEK_RATING",
  ADD_TO_BOOLEAN_MAP = "ADD_TO_BOOLEAN_MAP",
  TOGGLE_GLOBAL_CHAT_POPUP = "TOGGLE_GLOBAL_CHAT_POPUP",
  TOGGLE_REDEEM_POPUP = "TOGGLE_REDEEM_POPUP",
  TOGGLE_SCAM_ALERT_POPUP = "TOGGLE_SCAM_ALERT_POPUP",
  UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS",
  TOGGLE_NOTIFICATIONS_POPUP = "TOGGLE_NOTIFICATIONS_POPUP",
  TOGGLE_MENU_LEFT = "TOGGLE_MENU_LEFT",
}

// 4 - DEFINE ACTION INTERFACE
interface ZoomInAction {
  type: ActionType.ZOOM_IN;
  value: number;
}

interface ToggleNftPopupAction {
  type: ActionType.TOGGLE_NFT_POPUP;
}

interface ToggleNftConfirmMintingPopupAction {
  type: ActionType.TOGGLE_NFT_CONFIRM_MINTING_POPUP;
}

interface UpdateStatisticAction {
  type: ActionType.UPDATE_STATISTIC;
  statistic: Statistic;
}

interface ToggleNotificationsPopupAction {
  type: ActionType.TOGGLE_NOTIFICATIONS_POPUP;
}

interface UpdateNotificationsAction {
  type: ActionType.UPDATE_NOTIFICATIONS;
  notifications: INotification[];
}

interface ToggleScamAlertPopupAction {
  type: ActionType.TOGGLE_SCAM_ALERT_POPUP;
}

interface ToggleGlobalChatPopupAction {
  type: ActionType.TOGGLE_GLOBAL_CHAT_POPUP;
}

interface ToggleRedeemPopupAction {
  type: ActionType.TOGGLE_REDEEM_POPUP;
}

interface AddToBooleanMapAction {
  type: ActionType.ADD_TO_BOOLEAN_MAP;
  x: number;
  y: number;
}

interface SetMostViewedRatingAction {
  type: ActionType.SET_MOST_VIEWED_RATING;
  hexagens: Hexagen[];
}

interface SetMostViewedLastWeekRatingAction {
  type: ActionType.SET_MOST_VIEWED_LAST_WEEK_RATING;
  hexagens: Hexagen[];
}

interface SetLastCreatedRatingAction {
  type: ActionType.SET_LAST_CREATED_RATING;
  hexagens: Hexagen[];
}

interface SetTopPlayersRatingAction {
  type: ActionType.SET_TOP_PLAYERS_RATING;
  players: Player[];
}

interface SetMostUpvotedLastWeekRatingAction {
  type: ActionType.SET_MOST_UPVOTED_LAST_WEEK_RATING;
  hexagens: Hexagen[];
}

interface SetHighestPointsLastWeekRatingAction {
  type: ActionType.SET_HIGHEST_POINTS_LAST_WEEK_RATING;
  hexagens: Hexagen[];
}

interface ZoomOutAction {
  type: ActionType.ZOOM_OUT;
  value: number;
}

interface ToggleGeneratedAwardPopup {
  type: ActionType.TOGGLE_GENERATED_AWARD_POPUP;
  selectedHexInfo?: Hexagen;
}

interface ToggleMenuPopupAction {
  type: ActionType.TOGGLE_MENU_POPUP;
}

interface ToggleAboutGamePopupAction {
  type: ActionType.TOGGLE_ABOUT_GAME_POPUP;
}

interface SelectPlayerAction {
  type: ActionType.SELECT_PLAYER;
  uid: string;
}

interface DeselectPlayerAction {
  type: ActionType.DESELECT_PLAYER;
}

interface TogglePlayerInfoPopupAction {
  type: ActionType.TOGGLE_PLAYER_INFO_POPUP;
}
interface StartWorldDraggingAction {
  type: ActionType.START_WORLD_DRAGGING;
}

interface StopWorldDraggingAction {
  type: ActionType.STOP_WORLD_DRAGGING;
}

interface AddGenegatingNowHexAction {
  type: ActionType.ADD_GENERATING_NOW_HEX;
  x: number;
  y: number;
}

interface RemoveGenegatingNowHexAction {
  type: ActionType.REMOVE_GENERATING_NOW_HEX;
  x: number;
  y: number;
}

interface AddRegeneratingNowHexAction {
  type: ActionType.ADD_REGENERATING_NOW_HEX;
  x: number;
  y: number;
}

interface RemoveRegeneratingNowHexAction {
  type: ActionType.REMOVE_REGENERATING_NOW_HEX;
  x: number;
  y: number;
}

interface ToggleHexGeneratePopupAction {
  type: ActionType.TOGGLE_HEX_GENERATE_POPUP;
}

interface ToggleHexInfoPopupAction {
  type: ActionType.TOGGLE_HEX_INFO_POPUP;
}
interface ToggleLoginPopupAction {
  type: ActionType.TOGGLE_LOGIN_POPUP;
}

interface AddHexInfoAction {
  type: ActionType.ADD_SELECTED_HEX_INFO;
  hexInfo: Hexagen;
}

interface RemoveHexInfoAction {
  type: ActionType.REMOVE_SELECTED_HEX_INFO;
}

interface ToggleDeletePopupAction {
  type: ActionType.TOGGLE_DELETE_POPUP;
}

interface SelectHexAction {
  type: ActionType.SELECT_HEX;
  x: number;
  y: number;
}

interface DeselectHexAction {
  type: ActionType.DESELECT_HEX;
}

interface ChangeWorldCoordinatesAction {
  type: ActionType.CHANGE_WORLD_COORDINATES;
  x: number;
  y: number;
}

interface UpdatePlayerAction {
  type: ActionType.UPDATE_PLAYER;
  player: Player | null;
}

interface DeletePlayerAction {
  type: ActionType.DELETE_PLAYER;
}

interface TogglePlayerProfilePopupAction {
  type: ActionType.TOGGLE_PLAYER_PROFILE_POPUP;
}

interface ToggleMostViewedPopupAction {
  type: ActionType.TOGGLE_MOST_VIEWED_POPUP;
}

interface ToggleMenuLeftAction {
  type: ActionType.TOGGLE_MENU_LEFT;
}

// 5 - ADD ACTION INTERFACE TO UNION TYPE
export type AppAction = (
  | TogglePlayerProfilePopupAction
  | ToggleDeletePopupAction
  | ToggleLoginPopupAction
  | ToggleHexInfoPopupAction
  | ToggleHexGeneratePopupAction
  | UpdatePlayerAction
  | DeletePlayerAction
  | ChangeWorldCoordinatesAction
  | SelectHexAction
  | DeselectHexAction
  | AddHexInfoAction
  | RemoveHexInfoAction
  | AddGenegatingNowHexAction
  | RemoveGenegatingNowHexAction
  | AddRegeneratingNowHexAction
  | RemoveRegeneratingNowHexAction
  | StartWorldDraggingAction
  | StopWorldDraggingAction
  | TogglePlayerInfoPopupAction
  | SelectPlayerAction
  | DeselectPlayerAction
  | ToggleAboutGamePopupAction
  | ToggleMenuPopupAction
  | ZoomInAction
  | ZoomOutAction
  | UpdateStatisticAction
  | ToggleGeneratedAwardPopup
  | ToggleMostViewedPopupAction
  | SetMostViewedRatingAction
  | SetMostViewedLastWeekRatingAction
  | SetLastCreatedRatingAction
  | SetTopPlayersRatingAction
  | AddToBooleanMapAction
  | ToggleGlobalChatPopupAction
  | ToggleRedeemPopupAction
  | SetMostUpvotedLastWeekRatingAction
  | SetHighestPointsLastWeekRatingAction
  | ToggleScamAlertPopupAction
  | UpdateNotificationsAction
  | ToggleNotificationsPopupAction
  | ToggleNftPopupAction
  | ToggleNftConfirmMintingPopupAction
  | ToggleMenuLeftAction
) & { logggable?: boolean };

// 6 - UPDATE REDUCER FUNCTION
export const reducer = (state: AppState, action: AppAction): AppState => {
  let worldScale: number;

  switch (action.type) {
    case ActionType.TOGGLE_MOST_VIEWED_POPUP:
      return { ...state, isMostViewedPopupOpen: !state.isMostViewedPopupOpen };
    case ActionType.TOGGLE_NFT_POPUP:
      return { ...state, isNftPopupOpen: !state.isNftPopupOpen };
    case ActionType.TOGGLE_NFT_CONFIRM_MINTING_POPUP:
      return {
        ...state,
        isNFTConfirmMintingPopupOpen: !state.isNFTConfirmMintingPopupOpen,
      };
    case ActionType.TOGGLE_NOTIFICATIONS_POPUP:
      return {
        ...state,
        isNotificationsPopupOpen: !state.isNotificationsPopupOpen,
      };
    case ActionType.SET_MOST_VIEWED_RATING:
      return {
        ...state,
        ratings: { ...state.ratings, mostViewed: action.hexagens },
      };
    case ActionType.TOGGLE_SCAM_ALERT_POPUP:
      return {
        ...state,
        isNFTAlertPopupOpen: !state.isNFTAlertPopupOpen,
      };
    case ActionType.UPDATE_STATISTIC:
      return { ...state, statistic: action.statistic };
    case ActionType.ADD_TO_BOOLEAN_MAP:
      return {
        ...state,
        booleanMap: {
          ...state.booleanMap,
          [`${action.x}-${action.y}`]: true,
        },
      };
    case ActionType.SET_MOST_VIEWED_LAST_WEEK_RATING:
      return {
        ...state,
        ratings: { ...state.ratings, mostViewedLastWeek: action.hexagens },
      };
    case ActionType.SET_LAST_CREATED_RATING:
      return {
        ...state,
        ratings: { ...state.ratings, lastCreated: action.hexagens },
      };
    case ActionType.SET_TOP_PLAYERS_RATING:
      return {
        ...state,
        ratings: { ...state.ratings, topPlayers: action.players },
      };
    case ActionType.SET_MOST_UPVOTED_LAST_WEEK_RATING:
      return {
        ...state,
        ratings: { ...state.ratings, mostUpvotedLastWeek: action.hexagens },
      };
    case ActionType.SET_HIGHEST_POINTS_LAST_WEEK_RATING:
      console.log("action", action);
      return {
        ...state,
        ratings: { ...state.ratings, highestPointsLastWeek: action.hexagens },
      };
    case ActionType.ZOOM_IN:
      const newZoom = state.worldScale + action.value;
      worldScale = newZoom > 1.3 ? 1.3 : newZoom;

      const coords = calculateNewWorldCoordinates(state, worldScale);

      return {
        ...state,
        worldScale,
        worldX: coords.worldX,
        worldY: coords.worldY,
      };
    case ActionType.ZOOM_OUT:
      const minZoom =
        state.player?.points && state.player.points < 1000 ? 1.0 : 0.7;
      const newZoomOut = state.worldScale - action.value;
      worldScale = newZoomOut < minZoom ? minZoom : newZoomOut;

      const coords2 = calculateNewWorldCoordinates(state, worldScale);

      return {
        ...state,
        worldScale,
        worldX: coords2.worldX,
        worldY: coords2.worldY,
      };
    case ActionType.UPDATE_PLAYER:
      return { ...state, player: action.player };
    case ActionType.SELECT_PLAYER:
      return { ...state, selectedPlayerUID: action.uid };
    case ActionType.DESELECT_PLAYER:
      return { ...state, selectedPlayerUID: null };
    case ActionType.TOGGLE_MENU_POPUP:
      return { ...state, isMenuPopupOpen: !state.isMenuPopupOpen };
    case ActionType.TOGGLE_GLOBAL_CHAT_POPUP:
      return {
        ...state,
        isGlobalChatPopupOpen: !state.isGlobalChatPopupOpen,
      };
    case ActionType.TOGGLE_PLAYER_INFO_POPUP:
      return {
        ...state,
        isPlayerInfoPopupOpen: !state.isPlayerInfoPopupOpen,
      };
    case ActionType.DELETE_PLAYER:
      return { ...state, player: null };
    case ActionType.TOGGLE_PLAYER_PROFILE_POPUP:
      return {
        ...state,
        isPlayerProfilePopupOpen: !state.isPlayerProfilePopupOpen,
      };
    case ActionType.CHANGE_WORLD_COORDINATES:
      return { ...state, worldX: action.x, worldY: action.y };
    case ActionType.SELECT_HEX:
      return { ...state, selectedHexCoord: { x: action.x, y: action.y } };
    case ActionType.DESELECT_HEX:
      return { ...state, selectedHexCoord: null };
    case ActionType.TOGGLE_DELETE_POPUP:
      return {
        ...state,
        isConfirmDeletePopupOpen: !state.isConfirmDeletePopupOpen,
      };
    case ActionType.ADD_SELECTED_HEX_INFO:
      return { ...state, selectedHexInfo: action.hexInfo };
    case ActionType.REMOVE_SELECTED_HEX_INFO:
      return { ...state, selectedHexInfo: null };
    case ActionType.TOGGLE_LOGIN_POPUP:
      return { ...state, isLoginPopupOpen: !state.isLoginPopupOpen };
    case ActionType.TOGGLE_GENERATED_AWARD_POPUP:
      return {
        ...state,
        isGeneratedAwardPopupOpen: !state.isGeneratedAwardPopupOpen,
        selectedHexInfo: action.selectedHexInfo || null,
      };
    case ActionType.TOGGLE_HEX_INFO_POPUP:
      return { ...state, isHexInfoPopupOpen: !state.isHexInfoPopupOpen };
    case ActionType.TOGGLE_HEX_GENERATE_POPUP:
      return {
        ...state,
        isHexGeneratePopupOpen: !state.isHexGeneratePopupOpen,
      };
    case ActionType.TOGGLE_ABOUT_GAME_POPUP:
      return {
        ...state,
        isAboutGamePopupOpen: !state.isAboutGamePopupOpen,
      };
    case ActionType.TOGGLE_REDEEM_POPUP:
      return { ...state, isRedeemPopupOpen: !state.isRedeemPopupOpen };
    case ActionType.ADD_GENERATING_NOW_HEX:
      return {
        ...state,
        generatingNowHexs: [
          ...state.generatingNowHexs,
          { x: action.x, y: action.y },
        ],
      };
    case ActionType.REMOVE_GENERATING_NOW_HEX:
      return {
        ...state,
        generatingNowHexs: state.generatingNowHexs.filter(
          (hex) => !(hex.x === action.x && hex.y === action.y)
        ),
      };
    case ActionType.ADD_REGENERATING_NOW_HEX:
      return {
        ...state,
        regeneratingNowHexs: [
          ...state.regeneratingNowHexs,
          { x: action.x, y: action.y },
        ],
      };
    case ActionType.REMOVE_REGENERATING_NOW_HEX:
      return {
        ...state,
        regeneratingNowHexs: state.regeneratingNowHexs.filter(
          (hex) => !(hex.x === action.x && hex.y === action.y)
        ),
      };
    case ActionType.START_WORLD_DRAGGING:
      return { ...state, isWorldDragging: true };
    case ActionType.STOP_WORLD_DRAGGING:
      return { ...state, isWorldDragging: false };
    case ActionType.UPDATE_NOTIFICATIONS:
      return { ...state, notifications: action.notifications };
    case ActionType.TOGGLE_MENU_LEFT:
      return {
        ...state,
        isMenuLeftOpen: !state.isMenuLeftOpen,
      };
    default:
      return state;
  }
};

// DONE!
