import { useContext, useState } from "react";
import { ActionType } from "../State/State";
import { AppContext } from "../State/Context";
import { BasicPopup } from "./BasicPopup";
import { RequestMethod, askAPI } from "../utils/api";
import { Hexagen } from "../types";
import { Moon } from "../Misc/Moon";
import discord from "../Menu/discord.png";
import { getGenerationPenalty, findNeighboursNumber } from "../World/Hexagon";

const maxPromptLength = 100;

export const HexGeneratePopup: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const [prompt, setPrompt] = useState<string>("");
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [forceIsometry, setForceIsometry] = useState<boolean>(false);

  const handleForceIsometryChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setForceIsometry(event.target.checked);
  };

  const handlePromptChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setPrompt(event.target.value.slice(0, maxPromptLength));
  };

  const generateHexagen = async () => {
    if (isRequesting) return;

    setIsRequesting(true);

    if (state.selectedHexCoord) {
      const response = await askAPI<Hexagen>(
        "/api/hexagen/generate",
        RequestMethod.POST,
        {
          x: state.selectedHexCoord.x,
          y: state.selectedHexCoord.y,
          prompt,
          forceIsometry,
        }
      );

      if (!response) {
        closePopup();
        return;
      }

      setIsRequesting(false);

      dispatch({
        type: ActionType.ADD_GENERATING_NOW_HEX,
        x: state.selectedHexCoord?.x,
        y: state.selectedHexCoord?.y,
      });
    }
    closePopup();
  };

  const closePopup = () => {
    setIsRequesting(false);
    dispatch({ type: ActionType.TOGGLE_HEX_GENERATE_POPUP });
  };

  return state.isHexGeneratePopupOpen ? (
    <BasicPopup onClose={closePopup}>
      <div>
        <div className="text-lg mt-4 mb-2">
          Claim hexagon <span className="text-2xl">🎴</span>
        </div>
        <div className="text-sm font-normal opacity-50 mb-6">
          Coordinates: x:{state.selectedHexCoord?.x} y:
          {state.selectedHexCoord?.y}
        </div>
        <div>Create a prompt to generate an object within it.</div>
        <div className="mt-2">
          The generated hexagon will be evaluated by AI for creativity points.
          You'll receive a share of these points when others upvote your
          hexagon.
        </div>
        <div className="mt-6 mb-1 text-sm">Your Prompt:</div>
        <div>
          <textarea
            id="newName"
            value={prompt}
            onChange={handlePromptChange}
            className="w-full p-1 px-2 focus:outline-none h-[70px]"
          ></textarea>
          <div className="text-xs mb-4">
            {maxPromptLength - prompt.length} characters remaining
          </div>
          <div className="mb-4">
            <div className="flex items-center text-xs">
              <input
                type="checkbox"
                className="rainbow-checkbox"
                checked={forceIsometry}
                onChange={handleForceIsometryChange}
              />
              <span className="ml-2">Force isometric view</span>
            </div>
            <div className="text-xs opacity-30 mt-2">
              Use isometric AI model for generation.
            </div>
          </div>
          <div className="mt-4 mb-4 text-xs">
            Generation Cost:
            <span className="font-bold ml-2 mr-1">
              {state.selectedHexCoord
                ? Math.abs(
                    getGenerationPenalty(
                      findNeighboursNumber(
                        Number(state.selectedHexCoord.x),
                        Number(state.selectedHexCoord.y),
                        state.booleanMap
                      )
                    )
                  )
                : 0}
            </span>
            points
          </div>

          {state.player?.points || 0 >= 100 ? (
            isRequesting ? (
              <Moon />
            ) : (
              <button className="rainbow-hover-" onClick={generateHexagen}>
                Generate
              </button>
            )
          ) : (
            <div>
              <div className="text-red-800 mt-4">
                You are out of points for generating images 😟
              </div>

              <div className="mt-2">
                Check out our{" "}
                <a
                  href="https://discord.gg/yqkuuXnt2N"
                  target="_blank"
                  rel="noreferrer"
                  className="cursor-pointer"
                >
                  <img
                    src={discord}
                    alt=""
                    width={20}
                    className="inline-block mr-1 mt-[-3px]"
                  />
                  Discord
                </a>{" "}
                for a redeem code to get your first points.
              </div>
            </div>
          )}
        </div>
      </div>
    </BasicPopup>
  ) : null;
};
