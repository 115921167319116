import { useContext } from "react";
import { AppContext } from "../State/Context";
import { MenuBtn } from "./MenuBtn";
import { ActionType } from "../State/State";
import { IoMdChatboxes } from "react-icons/io";
import { IoMdNotificationsOutline } from "react-icons/io";
import { IoNotifications } from "react-icons/io5";
import { MdAutoGraph } from "react-icons/md";
// const animationArray = [
//   "NFT ",
//   ">FT ",
//   "N>T ",
//   "NF> ",
//   "NFT>",
//   "NFT ",
//   "NFT ",
//   "NFT ",
//   "NFT ",
//   "NFT ",
//   "NFT ",
// ];

export const MenuTop = () => {
  const { state, dispatch } = useContext(AppContext);
  // const [currentAnimationIndex, setCurrentAnimationIndex] = useState(0);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentAnimationIndex((prev) => (prev + 1) % animationArray.length);
  //   }, 500);

  //   return () => clearInterval(interval);
  // }, []);

  const player = state.player;

  const isUnreadNotifications = state.notifications.some(
    (notification) => !notification.isRead
  );

  const onNotificationsClick = () => {
    dispatch({ type: ActionType.TOGGLE_NOTIFICATIONS_POPUP });
  };

  return (
    <div className="fixed top-0 left-0 right-0 p-3 z-50">
      <div className="select-none absolute top-0 left-0 right-0 p-2 bg-[antiquewhite]  flex justify-between items-center">
        <div className="text-lg pl-2 flex">
          <div
            onClick={() => window.open("https://hexagen.world", "_blank")}
            className="cursor-pointer hover:underline"
          >
            hexagen⬢world
          </div>
          <div className="text-[10px] leading-3 pl-2 hidden sm:block">
            {state.statistic?.totalPlayers} players &
            <br />
            {state.statistic?.totalHexagons} hexagens
          </div>
          {/* <div className="text-[10px] leading-3 mt-[2px] pl-2 hidden sm:block">
            <span className="rainbow-color mr-1">⬢</span>Extra creativity
            <br />
            experiment
          </div> */}
        </div>
        <div className="flex translate-y-[2px]">
          {/* <div
            onClick={onNftClick}
            className="flex text-[20px] mr-3 cursor-pointer"
            title="NFTs"
          >
            <div className="text-[12px] whitespace-pre translate-y-[1px] pr-1">
              {animationArray[currentAnimationIndex]}
            </div>
            <RiNftFill />
          </div> */}
          {player && (
            <>
              <div
                onClick={onNotificationsClick}
                className="text-[20px] mr-3 cursor-pointer"
                title="Notifications"
              >
                {isUnreadNotifications ? (
                  <div className="translate-y-[1px]">
                    <IoNotifications className="animate-blink-red" />
                  </div>
                ) : (
                  <IoMdNotificationsOutline />
                )}
              </div>
            </>
          )}
          <div
            onClick={() =>
              dispatch({ type: ActionType.TOGGLE_GLOBAL_CHAT_POPUP })
            }
            className="text-[20px] mr-4 cursor-pointer"
            title="Comments"
          >
            <IoMdChatboxes />
          </div>
          <div
            onClick={() => dispatch({ type: ActionType.TOGGLE_MENU_LEFT })}
            className="text-center pr-4 cursor-pointer hover:underline text-[20px]"
            title="Ratings"
          >
            <MdAutoGraph />
          </div>
          <MenuBtn />
        </div>
      </div>
    </div>
  );
};
