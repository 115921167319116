export const isDevelopment = process.env.NODE_ENV === "development";

export const isMobile = () => {
  return typeof window !== "undefined" && window.innerWidth < 600;
};

const imgurl = isDevelopment ? "/api/devimages/" : "/images/";
export const img = (name: string) => imgurl + name;

export const openseaURL = isDevelopment
  ? "https://opensea.io/assets/matic/0x50838a31ff6ac58fb5c9dd6677f329915fcb83a6/"
  : "https://opensea.io/assets/matic/0x252B85B3fDC807D2eE0dF1c26d5E4d2D4e7A57Dd/";

export const rootURL = isDevelopment
  ? "http://localhost:3000"
  : "https://www.hexagen.world";
